import Container from "react-bootstrap/Container";
import ProjectRow, {ImageFormat} from "../components/ProjectRow";
import {useEffect} from "react";
import AOS from "aos";

export default function CurrentProjects() {
    useEffect(() => {
        AOS.refresh();
    });

    return (
        <section id="projects" className="features">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Projekte</h2>
                <p>Verschaffen Sie sich einen Überblick über die laufenden Projekte:</p>
            </Container>
            <Container>
                <ProjectRow
                    id="bauerngarten"
                    title="Bauerngarten"
                    text={"Im Bauerngarten ackern, säen und ernten wir mit den Kindern im Kreislauf der Natur. Die " +
                        "Kinder erfahren, wie Obst und Gemüse wachsen, welche Arbeitsschritte und welche Bedinungen " +
                        "für ein erfolgreiches Wachstum notwendig sind. Sie erfahren, wie viel Mühe der Anbau von Obst " +
                        "und Gemüse bereitet. Umso besser schmecken im Anschluss die selbst gehegten und gepflegten " +
                        "Früchte. Gleichzeitig erleben die Kinder Nachhaltigkeit und entwickeln einen bewussten sowie " +
                        "wertschätzenden Umgang mit Lebensmitteln."}
                    sourceImages={
                        [
                            {id: 0, src: require("../gfx/bauernGarten.png"), alt: "Bauerngarten"}
                        ]
                    }
                    format={ImageFormat.RIGHT}
                    fadeDirection="right"
                />

                <ProjectRow
                    id="bauwagen"
                    title="Bauwagen"
                    text={"Der Bauwagen dient als Aufenthaltsort oder kann für Projektarbeit genutzt werden. In einem " +
                        "Künstlerptojekt haben Schüler der GeLoWe dem  Bauwagen zusätzlichen Charme verliehen."}
                    sourceImages={
                        [
                            {id: 0, src: require("../gfx/bauWagen.png"), alt: "Bauwagen"}
                        ]
                    }
                    format={ImageFormat.LEFT}
                    fadeDirection="left"
                />

                <ProjectRow
                    id="okraundkohl"
                    title="Okra & Kohl"
                    titleImage={{src: require("../gfx/okraundKohlLogo.png"), alt: "Okra & Kohl Logo"}}
                    text={"Okra & Kohl stehen als Synonym für die Küchen verschiedener Kulturen. Mit diesem Projekt " +
                        "möchten wir die Menschen verschiedener Kulturen in Westerkappeln über das gemeinsame Gärtnern, " +
                        "Kochen und Essen zusammenbringen.\nDu hast Spaß an Garten und Gemeinschaft?\nWir freuen uns, " +
                        "dass wir mit unserem Projekt Okra & Kohl Teil des Bundesprogramms „Gesellschaftlicher " +
                        "Zusammenhalt. Vor Ort. Vernetzt. Verbunden.“ geworden sind.\n Im Regenbogenland gärtnern wir " +
                        "gemeinsam auf einer ca. 12 x 12 m großen " +
                        "Gartenfläche, auch zwei Frühbeete und ein Gewächshaus stehen uns zur Verfügung.\n" +
                        "\n" +
                        "Neben dem Gartenbereich bietet das Regenbogenland auch viel Platz zum Spielen und Verweilen " +
                        "für die ganze Familie.\n" +
                        "Wenn du dich gerne informieren oder mitmachen möchtest, dann schau mal vorbei oder ruf " +
                        "an: 05404 - 919627."}
                    sourceImages={
                        [
                            {id: 0, src: require("../gfx/kohlundOkra.jpeg"), alt: "Okra & Kohl"}
                        ]
                    }
                    sponsorImage={{src: require("../gfx/fördererOkraundKohl.jpg"), alt: "Okra & Kohl Förderer"}}
                    format={ImageFormat.RIGHT}
                    fadeDirection="right"
                />

                <ProjectRow
                    id="barfußpark"
                    title="Barfußpark (im Aufbau)"
                    text={"Aktuell befinden wir uns mit vielen ehrenamtlichen Helfern und dem evangelischen " +
                        "Familienzentrum Anne Frank im Aufbau eines Barfußspfades."}
                    sourceImages={
                        [
                            {id: 0, src: require("../gfx/barfußPark.png"), alt: "Barfußpark"}
                        ]
                    }
                    format={ImageFormat.LEFT}
                    fadeDirection="left"
                />
                <ProjectRow
                    id="biologischevielfalt"
                    title="Biologische Vielfalt"
                    text={"Biologische Vielfalt zu fördern und zu erhalten ist ein wichtiger Bestandteil des Konzeptes " +
                        "vom Regenbogenland.\nDie Schöpfung zu wahren und Nachhaltigkeit zu fördern ist uns ein " +
                        "großes Anliegen. Durch unterschiedliche Projekte und feste Bestandteile im Regenbogenland, wie " +
                        "zum Beispiel Workshops zum Nistkästen bauen, die Blühwiese, die Streuobstwiese im Schafgehege ..., " +
                        "setzen wir Nachhaltigkeitsziele des BNE um und vermitteln sie an Besucher."}
                    sourceImages={
                        [
                            {id: 0, src: require("../gfx/biologischeVielfalt.png"), alt: "Biologische Vielfalt"},
                            {id: 1, src: require("../gfx/biologischeVielfalt2.png"), alt: "Biologische Vielfalt 2"}
                        ]
                    }
                    format={ImageFormat.STACKED}
                    fadeDirection="right"
                />
            </Container>
        </section>
    );
}
