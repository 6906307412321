import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";

export default function BottomBar() {
    return (
        <Navbar bg="secondary" variant="dark">
            <Container>
                <Navbar.Brand as={NavLink} to="/">
                    <img
                        alt="Regenbogenland Logo"
                        src={require("../gfx/logoSmall.png")}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link as={NavLink} to="/impressum">Impressum</Nav.Link>
                    <Nav.Link as={NavLink} to="/datenschutz">Datenschutz</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}