import {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Placeholder from "react-bootstrap/Placeholder";
import AOS from "aos";

export const enum ImageFormat {
    STACKED = 0,
    LEFT = 1,
    RIGHT = 2
}

export default function ProjectRow(props: any) {
    const [loadCounter, setLoadCounter] = useState(0);

    useEffect(() => {
        AOS.refresh();
    });

    if(props.sourceImages.length === 2 && props.format === ImageFormat.STACKED) {
        return (
            <Row id={props.id} className="gy-4 align-items-center features-item bg-secondary p-5 shadow" data-aos={"fade-" + props.fadeDirection} data-aos-anchor-placement="top-center">
                <Col lg={5} className={loadCounter === props.sourceImages.length ? "order-2 order-lg-1" : ""}>
                    {loadCounter === props.sourceImages.length ?
                        <>
                            {props.titleImage ?
                                <img
                                    src={props.titleImage.src}
                                    alt={props.titleImage.alt}
                                    className="mb-5 border-0 shadow-none"
                                /> : null
                            }
                            <h3>{props.title}</h3>
                            {props.text.split("\n").map((str: string) => <p key={str}>{str}</p>)}
                            {props.sponsorImage ?
                                <img
                                    src={props.sponsorImage.src}
                                    alt={props.sponsorImage.alt}
                                    className="mb-5 border-0 shadow-none w-75 mt-3 mx-auto"
                                /> : null
                            }
                        </>
                        : (
                            <>
                                <Placeholder xs={6} size="lg" bg="primary"/>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                    <Placeholder xs={6}/> <Placeholder xs={2}/>
                                </Placeholder>
                            </>
                        )}
                </Col>
                <Col lg={7} className={loadCounter === props.sourceImages.length ? "order-1 order-lg-2 d-flex align-items-center" : ""}>
                    <div className={loadCounter === props.sourceImages.length ? "image-stack" : ""}>
                        <img
                            src={props.sourceImages[0].src}
                            alt={props.sourceImages[0].alt}
                            style={loadCounter === props.sourceImages.length ? {} : {display: "none"}}
                            className="stack-front hvr-grow-shadow"
                            onLoad={() => setLoadCounter(prevCount => prevCount + 1)}
                        />
                        <img
                            src={props.sourceImages[1].src}
                            alt={props.sourceImages[1].alt}
                            style={loadCounter === props.sourceImages.length ? {} : {display: "none"}}
                            className="stack-back hvr-grow-shadow"
                            onLoad={() => setLoadCounter(prevCount => prevCount + 1)}
                        />
                        {loadCounter !== props.sourceImages.length &&
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={2}/> <Placeholder xs={7}/>
                            </Placeholder>
                        }
                    </div>
                </Col>
            </Row>
        );
    } else if(props.sourceImages.length === 1 && props.format === ImageFormat.LEFT) {
        return (
            <Row id={props.id} className="gy-4 align-items-stretch justify-content-between features-item bg-secondary p-5 shadow" data-aos={"fade-" + props.fadeDirection} data-aos-anchor-placement="top-center">
                <Col lg={6} className={loadCounter === props.sourceImages.length ? "d-flex align-items-center features-img-bg" : ""}>
                    <img
                        src={props.sourceImages[0].src}
                        alt={props.sourceImages[0].alt}
                        style={loadCounter === props.sourceImages.length ? {} : {display: "none"}}
                        className="img-fluid hvr-grow-shadow"
                        onLoad={() => setLoadCounter(prevCount => prevCount + 1)}
                    />
                    {loadCounter !== props.sourceImages.length &&
                        <>
                            <Placeholder xs={6} size="lg" bg="primary"/>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                <Placeholder xs={5}/> <Placeholder xs={7}/> <Placeholder xs={4}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={10}/> <Placeholder xs={7}/>{' '}
                                <Placeholder xs={4}/> <Placeholder xs={2}/> <Placeholder xs={7}/>
                            </Placeholder>
                        </>
                    }
                </Col>
                <Col lg={5} className={loadCounter === props.sourceImages.length ? "d-flex justify-content-center flex-column" : ""}>
                    {loadCounter === props.sourceImages.length ?
                        <>
                            {props.titleImage ?
                                <img
                                    src={props.titleImage.src}
                                    alt={props.titleImage.alt}
                                    className="mb-5 border-0 shadow-none"
                                /> : null
                            }
                            <h3>{props.title}</h3>
                            {props.text.split("\n").map((str: string) => <p key={str}>{str}</p>)}
                            {props.sponsorImage ?
                                <img
                                    src={props.sponsorImage.src}
                                    alt={props.sponsorImage.alt}
                                    className="mb-5 border-0 shadow-none w-75 mt-3 mx-auto"
                                /> : null
                            }
                        </>
                        : (
                            <>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={2}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                    <Placeholder xs={3}/> <Placeholder xs={4}/> <Placeholder xs={2}/>{' '}
                                    <Placeholder xs={6}/> <Placeholder xs={3}/> <Placeholder xs={7}/>{' '}
                                    <Placeholder xs={6}/> <Placeholder xs={3}/> <Placeholder xs={7}/>{' '}
                                    <Placeholder xs={3}/> <Placeholder xs={4}/> <Placeholder xs={2}/>{' '}
                                    <Placeholder xs={4}/> <Placeholder xs={2}/> <Placeholder xs={7}/>
                                </Placeholder>
                            </>
                        )}
                </Col>
            </Row>
        );
    } else if(props.sourceImages.length === 1 && props.format === ImageFormat.RIGHT) {
        return(
            <Row id={props.id} className="gy-4 align-items-stretch justify-content-between features-item bg-secondary p-5 shadow" data-aos={"fade-" + props.fadeDirection} data-aos-anchor-placement="top-center">
                <Col lg={5} className={loadCounter === props.sourceImages.length ? "d-flex justify-content-center flex-column" : ""}>
                    {loadCounter === props.sourceImages.length ?
                        <>
                            {props.titleImage ?
                                <img
                                    src={props.titleImage.src}
                                    alt={props.titleImage.alt}
                                    className="mb-5 border-0 shadow-none"
                                /> : null
                            }
                            <h3>{props.title}</h3>
                            {props.text.split("\n").map((str: string) => <p key={str}>{str}</p>)}
                            {props.sponsorImage ?
                                <img
                                    src={props.sponsorImage.src}
                                    alt={props.sponsorImage.alt}
                                    className="mb-5 border-0 shadow-none w-75 mt-3 mx-auto"
                                /> : null
                            }
                        </>
                        : (
                            <>
                                <Placeholder xs={6} size="lg" bg="primary"/>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                    <Placeholder xs={5}/> <Placeholder xs={7}/> <Placeholder xs={4}/>{' '}
                                    <Placeholder xs={6}/> <Placeholder xs={10}/> <Placeholder xs={7}/>{' '}
                                    <Placeholder xs={4}/> <Placeholder xs={2}/> <Placeholder xs={7}/>
                                </Placeholder>
                            </>
                        )}
                </Col>
                <Col lg={6} className={loadCounter === props.sourceImages.length ? "d-flex align-items-center features-img-bg" : ""}>
                    <img
                        src={props.sourceImages[0].src}
                        alt={props.sourceImages[0].alt}
                        style={loadCounter === props.sourceImages.length ? {} : {display: "none"}}
                        className="img-fluid hvr-grow-shadow"
                        onLoad={() => setLoadCounter(prevCount => prevCount + 1)}
                    />
                    {loadCounter !== props.sourceImages.length &&
                        <>
                            <Placeholder as="p" animation="glow">
                                <Placeholder xs={2}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                                <Placeholder xs={3}/> <Placeholder xs={4}/> <Placeholder xs={2}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={3}/> <Placeholder xs={7}/>{' '}
                                <Placeholder xs={6}/> <Placeholder xs={3}/> <Placeholder xs={7}/>{' '}
                                <Placeholder xs={3}/> <Placeholder xs={4}/> <Placeholder xs={2}/>{' '}
                                <Placeholder xs={4}/> <Placeholder xs={2}/> <Placeholder xs={7}/>
                            </Placeholder>
                        </>
                    }
                </Col>
            </Row>
        );
    } else {
        return(
            <></>
        );
    }
}