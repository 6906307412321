import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import luxon2Plugin from "@fullcalendar/luxon2"
import deLocale from "@fullcalendar/core/locales/de";
import interactionPlugin, {DateClickArg} from "@fullcalendar/interaction";
import BookingModal from "../components/BookingModal";
import {ToastContainer} from "react-toastify";
import AOS from "aos";

export default function ReservationCalendar(props: any) {
    const [modalShow, setModalShow] = useState(false);
    const [clickedDate, setClickedDate] = useState(new Date(0));
    const [timeSlotsDisabled, setTimeSlotsDisabled] = useState<{ [key: string]: string[] }>({});

    useEffect(() => {
        AOS.refresh();
    });

    function handleDateClick(info: DateClickArg) {
        let calendarApi = info.view.calendar;
        let temp: { [key: string]: string[] } = {};

        calendarApi.getEvents().forEach(function (current) {
            if (!current.start || !current.end) {
                return;
            }

            let dateString: string = current.start.toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            });

            let startTime =  ("0" + current.start.getHours()).slice(-2);
            let endTime = ("0" + current.end.getHours()).slice(-2);
            let slotString = startTime + "-" + endTime;

            if(typeof temp[dateString] === "undefined") {
                temp[dateString] = [];
            }

            temp[dateString].push(slotString);
        });

        setTimeSlotsDisabled(temp);
        setModalShow(true);
        setClickedDate(info.date);
    }

    return (
        <section id="calendar" className="calendar">
            <Container className="section-title" data-aos="fade-up" data-aos-anchor-placement="top-center">
                <h2>Terminkalender</h2>
                <p>Sie möchten die Ressourcen im Regenbogenland nutzen?</p>
                <p>Wählen Sie einen Tag und buchen Sie einen Termin!</p>
            </Container>
            <Container data-aos="fade-up" data-aos-anchor-placement="top-center">
                <Row className="pt-3 border-top border-5 border-primary">
                    <Col>
                        <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin, luxon2Plugin, interactionPlugin ]}
                            locale={deLocale}
                            timeZone="Europe/Berlin"
                            initialView="dayGridMonth"
                            height={750}
                            headerToolbar={
                                {
                                    start: "prev next today",
                                    center: "title",
                                    end: "dayGridMonth timeGridWeek"
                                }
                            }
                            slotDuration="00:30:00"
                            slotMinTime="08:00:00"
                            slotMaxTime="20:00:00"
                            validRange={ (nowDate) => {
                                let tempDate: Date = new Date(nowDate.getTime());
                                tempDate.setMonth(tempDate.getMonth()+ 6)
                                    return {
                                        start: nowDate,
                                        end: tempDate
                                    };
                                }
                            }
                            selectable={true}
                            displayEventEnd={true}
                            selectAllow={(span) => {
                                    return span.end.getTime() / 1000 - span.start.getTime() / 1000 <= 86400;
                                }
                            }
                            dayMaxEvents={6}
                            dateClick={(info) => handleDateClick(info)}
                            events={{
                                url: "https://regenbogenland-westerkappeln.de/backend/fetchcalendarevents.php",
                                method: "POST",
                                failure: function () {
                                    props.toastfunction("RC", 2, "Konnte die Veranstaltungen im Kalender nicht laden");
                                }
                            }}

                            eventDisplay="block"
                            eventTextColor="white"
                            eventColor="#73A24E"
                            allDaySlot={false}
                            rerenderDelay={250}
                        />
                    </Col>
                </Row>
            </Container>

            <BookingModal
                show={modalShow}
                date={clickedDate}
                showfunction={setModalShow}
                toastfunction={props.toastfunction}
                timeSlotsDisabled={timeSlotsDisabled}
            />

            <ToastContainer
                enableMultiContainer
                containerId="RC"
                position="bottom-center"
                theme="colored"
                hideProgressBar={true}
            />
        </section>
    );
}