import {useState} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {Placeholder} from "react-bootstrap";

export default function OverviewCard(props: any) {
    const [sourceLoaded, setSourceLoaded] = useState(false);

    const buttonOnClick = (e: any) => {
        e.preventDefault();

        let element: HTMLAnchorElement = document.querySelector(props.refTo);
        if(!!element && !!element.id) {
            document.getElementById(element.id)?.scrollIntoView({ behavior: "smooth"});
        }
    };

    return (
        <Card
            bg={sourceLoaded ? "body" : undefined}
            border={sourceLoaded ? "0" : undefined}
            className={sourceLoaded ? "card-hover text-white shadow" : undefined}
        >

            <Card.Img
                src={props.sourcePath}
                variant="top"
                onLoad={() => setSourceLoaded(true)}
                style={sourceLoaded ? {} : { display: "none" }}
            />

            {sourceLoaded ?
                <>
                    <div className="card-hover-overlay"></div>
                    <Card.ImgOverlay className="card-hover-content">
                        <Card.Title className="card-hover-title">
                            {props.cardTitle}
                        </Card.Title>
                        <Card.Text className="card-hover-description">
                            {props.cardText}
                        </Card.Text>
                        <Button onClick={(event) => buttonOnClick(event)} href={props.refTo} variant="primary" size="sm" className="card-hover-button">Mehr erfahren</Button>
                    </Card.ImgOverlay>
                </>
                : (
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={4} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={2} />
                    </Placeholder>
                    <Placeholder.Button variant="primary" xs={3} />
                </Card.Body>
            )}

        </Card>
    );
}