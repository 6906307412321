import {Controller, useForm} from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useEffect} from "react";
import {FloatingLabel} from "react-bootstrap";

export default function BookingModal(props: any) {
    const {
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { isSubmitSuccessful, errors, dirtyFields }} = useForm({mode: "onChange"});

    const onSubmit = async (data: any) => {
        let body = {
            datum: props.date.toLocaleDateString("de-DE", {year: "numeric", month: "2-digit", day: "2-digit"}),
            zeitslots: data.zeitslots,
            vorname: data.vorname,
            nachname: data.nachname,
            email: data.email,
            telefon: data.telefon,
            bemerkung: data.bemerkung
        }

        fetch("https://regenbogenland-westerkappeln.de/backend/booking.php", {
            method: "POST",
            body: JSON.stringify(body)
        })
            .then((response) => response.json())
            .then((data) => {
                if(data.erfolg === "Ok") {
                    props.toastfunction("RC", 1, "Reservierung erfolgreich");
                    props.toastfunction("RC", 3, "Die Reservierungsdetails wurden Ihnen per E-Mail zugesendet");
                    props.showfunction(false);
                } else {
                    props.toastfunction("RC", 2, "Bei Ihrer Anfrage ist ein Fehler aufgetreten. Grund: " + data.fehler);
                    props.showfunction(false);
                }
        }).catch(error => {
            props.toastfunction("RC", 2, "Bei Ihrer Anfrage ist ein Fehler aufgetreten");
            props.showfunction(false);
        });
    }

    const hideAndResetModal = () => {
        props.showfunction(false);
        resetField("zeitslots");
    }

    const timeslotOptions = [
        { id: "08-10", label: "08:00 - 10:00", value: 1},
        { id: "10-12", label: "10:00 - 12:00", value: 2},
        { id: "12-14", label: "12:00 - 14:00", value: 3},
        { id: "14-16", label: "14:00 - 16:00", value: 4},
        { id: "16-18", label: "16:00 - 18:00", value: 5},
        { id: "18-20", label: "18:00 - 20:00", value: 6}
    ];

    useEffect(() => {
        if (!isSubmitSuccessful) { return }

        reset({
            zeitslots: {"08-10": false, "10-12": false, "12-14": false, "14-16": false, "16-18": false, "18-20": false},
            vorname: "",
            nachname: "",
            email: "",
            telefon: "",
            bemerkung: ""
        });
    }, [isSubmitSuccessful, reset]);

    return(
        <Modal show={props.show} date={props.date} onHide={hideAndResetModal} centered style={{minWidth: "270px"}}>
            <Form id="reservationForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton className="text-center">
                    <Modal.Title>
                        Neue Reservierungsanfrage für den {props.date.toLocaleDateString("de-DE", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Gewünschte Zeitslots</Form.Label>
                        <Controller
                            name="zeitslots"
                            control={control}
                            rules={{
                                validate: (zeitslots: any) =>
                                    Object.values(zeitslots).some((value) => value) ||
                                    "Wählen Sie bitte mindestens einen Zeitslot aus",
                            }}
                            render={({ field }) => (
                                <Container>
                                    <Row>
                                        <Col>
                                            {timeslotOptions.slice(0, 3).map((option) => (
                                                <Form.Check
                                                    type="checkbox"
                                                    key={option.id}
                                                    value={option.value}
                                                    label={option.label}
                                                    checked={field.value[option.id]}
                                                    onChange={(e) =>
                                                        field.onChange({
                                                            ...field.value,
                                                            [option.id]: e.target.checked,
                                                        })
                                                    }
                                                    isInvalid={!!errors.zeitslots}
                                                    isValid={dirtyFields.zeitslots && !errors.zeitslots}

                                                    onBlur={field.onBlur}
                                                    ref={field.ref}
                                                    placeholder="Check"
                                                    disabled={!!props.timeSlotsDisabled[props.date.toLocaleDateString("de-DE", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit"
                                                    })]?.includes(option.id)}
                                                />
                                            ))}
                                        </Col>
                                        <Col>
                                            {timeslotOptions.slice(3, 6).map((option) => (
                                                <Form.Check
                                                    type="checkbox"
                                                    key={option.id}
                                                    value={option.value}
                                                    label={option.label}
                                                    checked={field.value[option.id]}
                                                    onChange={(e) =>
                                                        field.onChange({
                                                            ...field.value,
                                                            [option.id]: e.target.checked,
                                                        })
                                                    }
                                                    isInvalid={!!errors.zeitslots}
                                                    isValid={dirtyFields.zeitslots && !errors.zeitslots}

                                                    onBlur={field.onBlur}
                                                    ref={field.ref}
                                                    placeholder="Check"
                                                    disabled={!!props.timeSlotsDisabled[props.date.toLocaleDateString("de-DE", {
                                                        year: "numeric",
                                                        month: "2-digit",
                                                        day: "2-digit"
                                                    })]?.includes(option.id)}
                                                />
                                            ))}
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                            defaultValue={{"08-10": false, "10-12": false,
                                           "12-14": false, "14-16": false,
                                           "16-18": false, "18-20": false}}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors.zeitslots && <p>{errors.zeitslots?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <FloatingLabel label="Vorname" className="mb-3">
                        <Controller
                            name="vorname"
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie einen Vornamen an"},
                                maxLength: {value: 30, message: "Ihr Vorname darf maximal 30 Zeichen haben"},
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors.vorname}
                                    isValid={dirtyFields.vorname && !errors.vorname}
                                    ref={ref}
                                    type="text"
                                    autoFocus
                                    placeholder="Max"
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.vorname && <p>{errors.vorname?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel label="Nachname" className="mb-3">
                        <Controller
                            name="nachname"
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie einen Nachnamen an"},
                                maxLength: {value: 30, message: "Ihr Nachname darf maximal 30 Zeichen haben"},
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors.nachname}
                                    isValid={dirtyFields.nachname && !errors.nachname}
                                    ref={ref}
                                    type="text"
                                    placeholder="Mustermann"
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.nachname && <p>{errors.nachname?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel label="E-Mail" className="mb-3">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie eine E-Mail-Adresse an"},
                                pattern: {value: /\S+@\S+\.\S+/, message: "Überprüfen Sie das Format Ihrer E-Mail-Adresse"},
                                maxLength: {value: 50, message: "Ihre E-Mail-Adresse darf maximal 50 Zeichen haben"},
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors.email}
                                    isValid={dirtyFields.email && !errors.email}
                                    ref={ref}
                                    type="email"
                                    placeholder="nutzer@beispiel.de"
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.email && <p>{errors.email?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel label="Telefon" className="mb-3">
                        <Controller
                            name="telefon"
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte geben Sie eine Telefonnummer an"},
                                minLength: {value: 6, message: "Ihre Telefonnummer benötigt mindestens 6 Ziffern"},
                                maxLength: {value: 13, message: "Ihre Telefonnummer darf maximal 13 Ziffern haben"},
                                pattern: {value: /^\d+$/, message: "Ihre Teflonnummer darf nur aus Zahlen bestehen"}
                            }}
                            render={({ field: { onChange, onBlur, value, ref }}) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors.telefon}
                                    isValid={dirtyFields.telefon && !errors.telefon}
                                    ref={ref}
                                    type="text"
                                    placeholder="012345678910"
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.telefon && <p>{errors.telefon?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel label="Was haben Sie vor?" className="mb-3">
                        <Controller
                            name="bemerkung"
                            control={control}
                            rules={{
                                required: {value: true, message: "Bitte beschreiben Sie kurz Ihr Vorhaben"},
                                minLength: {value: 20, message: "Ihre Beschreibung sollte etwas länger sein"},
                                maxLength: {value: 255, message: "Ihre Beschreibung ist zu lang"}
                            }}
                            render={({ field: { onChange, onBlur, value, ref }}) => (
                                <Form.Control
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    isInvalid={!!errors.bemerkung}
                                    isValid={dirtyFields.bemerkung && !errors.bemerkung}
                                    ref={ref}
                                    type="text"
                                    as="textarea"
                                    rows={4}
                                    style={{minHeight: "140px", maxHeight: "150px"}}
                                    placeholder="Was haben Sie vor?"
                                />
                            )}
                            defaultValue=""
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.bemerkung && <p>{errors.bemerkung?.message?.toString()}</p>}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Reservierung abschicken
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}